.picker {
  border: 1px solid #6f6f6f;
  border-style: dashed;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
}

.container {
  max-width: 300px;
  margin: 100px auto;
}

.zone {
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px solid #6f6f6f;
  border-style: dashed;
}

.drop-zone {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drag-files,
.drop-files {
  border-radius: inherit;
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: background-color 300ms;
}

.selected {
  position: relative;
}

.actions {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: color-mix(in srgb, white, transparent 90%);
  right: 0;
  bottom: 0;
  width: 100%;
  gap: 10px;
  font-size: 0.7em;
  backdrop-filter: blur(1px);
}

.actions button {
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: border-color 0.25s;
  padding: 10px 15px;
}

.uploaded-txt {
  background-color: rgb(34, 116, 135);
  padding: 10px;
  border-radius: 3px;
}

.upload-btn {
  background-color: rgb(34, 135, 34);
}

.cancel-btn {
  background-color: rgb(135, 34, 34);
}

.drop-files {
  background-color: #343333;
  color: #545353;
}

.container img {
  width: 100%;
  display: block;
  border-radius: inherit;
}

.uploaded-url {
  display: block;
  width: 100%;
  font-size: 1em;
  word-wrap: break-word;
  margin: 10px 0;
  color: orange;
  text-decoration: underline;
}
